import React, { useState } from 'react'
import MainLayout from '../layouts/Main'
import '../template-styles/our-vision.scss'
import Divider from '../Components/General/Divider/Divider';
import Button from '../Components/Button/Button';
import Arrow from '../Components/Arrow/Arrow'
import Banner from '../Components/Header/Banner'
import { graphql } from 'gatsby';

const sanitizeDescription = (description) => {
  // Remove <p> tags from the description
  return description.replace(/<p>/g, '').replace(/<\/p>/g, '');
};

function OurVision({data, props}) {

  const sections = data?.wordPress?.hPageTemplates?.nodes[0]?.hPages?.nodes[0]?.studioFields?.ourVision;

  function getSEOForPage(data) {
    return data?.wordPress?.hPageTemplates?.nodes[0].hPages.nodes[0]
  }

  const pageSEO = getSEOForPage(data);

  const [flippedCards, setFlippedCards] = useState([]);

  // Function to handle card flip
  const handleCardFlip = (index) => {
    // Toggle the flipped state of the clicked card
    setFlippedCards((prevFlippedCards) => {
      const newFlippedCards = [...prevFlippedCards];
      newFlippedCards[index] = !newFlippedCards[index];
      return newFlippedCards;
    });
  };

  return (
    <MainLayout
    lang={"us"}
    languages={["us", "uk"]}
    className={"mask5"}
    defaultStickyMenu={true}
    {...props}
    seoMeta={pageSEO}
    >
      <Banner 
        top='117px'
      />      {sections.map((section, index) => {
        const type = section?.__typename
        switch (type) {
          case "WordPress_HPage_Studiofields_OurVision_ImageAndText":
          return (
            <>
            <div style={{ position: 'relative', backgroundColor: section.wrapperBackgroundColor }}>
              <div className="header-image-container">
                <div className="header-container">
                  <div dangerouslySetInnerHTML={{ __html: section?.header }} />
                  <div dangerouslySetInnerHTML={{ __html: section?.description || '' }} />
                </div>
                <img className="image-styles" src={section?.image?.sourceUrl || ''} alt="" />
              </div>
              {/* <div className="bg-mask-test-pattern">
                <img src='/images/platform/Pattern-05.svg' className='header-image-background' />
              </div> */}
              {/* <div className="header-arrow-container">
              <Arrow className="header-arrow" dark />
              </div> */}
            </div>


  </>
          );
            case "WordPress_HPage_Studiofields_OurVision_LongDescriptionSection":
              return (
                <>
                <div style={{position: 'relative', padding: '2rem',backgroundColor: section?.wrapperBackgroundColor || '#191919'}}>
                <div className="long-description-container">
                  <div style={{color: section?.borderColor}} />
                  <span dangerouslySetInnerHTML={{ __html: section?.description || '' }} />
                  <div style={{color: section?.borderColor}} />
                </div>
                </div>
                </>
              );
            case "WordPress_HPage_Studiofields_OurVision_Divider":
              return (
                <Divider
                additionalClass={section.additionalclass}
                key={"section-" + index}
                bgColor={section.backgroundColor}
                maskColor={section.maskColor}
                direction={section.position.toLowerCase() + "-" + section.orientation.toLowerCase()}
                />
              );
            case "WordPress_HPage_Studiofields_OurVision_SkewedDivider":
              return (
                <div
                  style={{position: 'relative'}}
                >
                  <div className="skewed-divider"
                    style={{backgroundColor: section.backgroundColor}}
                  />
                </div>
              );
            case "WordPress_HPage_Studiofields_OurVision_FlipCardSection":
              return (
                <div style={{ backgroundColor: section?.wrapperBackgroundColor, position: 'relative' }}>
                  <div className="flip-card-desc-container">
                    <h2 style={{color:section?.textColor || '#ffffff'}}>{section?.mainHeader}</h2>
                    <h4 style={{color:section?.textColor || '#ffffff'}}>{section?.secondaryHeader}</h4>
                    <p>{section?.mainDescription}</p>
                    <div className="flip-card-container">
                      {section?.cardFields?.map((card, index) => {
                        return (
                          <div
                            onClick={() => handleCardFlip(index)}
                            className={`our-vision-card ${flippedCards[index] ? 'is-flipped' : ''}`}
                            style={{
                              backgroundColor: flippedCards[index] ? section?.cardFields[0]?.cardBackBackgroundColor : section?.cardFields[0]?.cardFrontBackgroundColor,
                            }}
                            key={index}
                          >
                            <img
                            className={`${flippedCards ? `our-vision-card-icon` : `our-vision-card-icon`}`}
                              src='/images/swap-hover-icon-black.svg' 
                              alt="Hover icon" 
                            />
                            <img className={!flippedCards[index] ? 'our-vision-card-image' : 'none'} src={card?.cardImage?.sourceUrl && card?.cardImage.sourceUrl} alt='Card Image' />
                            <p
                            style={{
                              color: flippedCards[index] 
                                ? section?.cardFields[0]?.cardBackTextColor 
                                : section?.cardFields[0]?.cardFrontTextColor
                              ,
                              fontWeight: flippedCards[index] ? 400 : 700
                            }}
                            className={flippedCards[index] ? 'is-flipped' : ''}
                            dangerouslySetInnerHTML={{
                              __html: flippedCards[index]
                                ? sanitizeDescription(card?.cardBackDescription)
                                : sanitizeDescription(card?.cardFrontDescription)
                            }}>
                            </p>

                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            case "WordPress_HPage_Studiofields_OurVision_VideoSection":
              return (
                <div style={{position: 'relative', backgroundColor: section?.wrapperBackgroundColor}}>
                  <div className="our-vision-video-section-container">
                    <h2>{section?.mainHeader}</h2>
                    <h4>{section?.secondaryHeader}</h4>
                    <p>{section?.mainDescription}</p>
                    <div className="iframe-container">
                      <iframe
                        src={section?.video}
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded Video"
                      />
                    </div>
                  </div>
                </div>
              );
              case "WordPress_HPage_Studiofields_OurVision_PlatformApplicationSection":
                return (
                  <>
                    <section className='our-vision-platform-section' style={{ backgroundColor: section.wrapperBackgroundColor || '#f5ebe0' }}>
                      <div className="our-vision-platform-container">
                        <h2>{section.mainHeader || 'Applications of the Wunderkind Platform'}</h2>
                        <h4>{section.subHeader || 'Streamline the entire marketing build, report, and optimization process.'}</h4>
                        {section.imageAndTextCta && (
                          section.imageAndTextCta.map((ctaItem, index) => (
                            <div key={index} className={`our-vision-platform-image-text-container ${ctaItem.leftOrRight === "Right" ? 'flex-row-reverse' : ''}`}>
                              <div className={`our-vision-platform-header-desc-btn-container`}>
                                <h3>{ctaItem.header || 'Studio'}</h3>
                                <p>{ctaItem.description || 'Studio streamlines the production stage of design to increase the agility with which brands create best-in-class creatives and experiences.'}</p>
                                {ctaItem.cta && <a href={`${ctaItem.ctaHref}`}><Button className="btn btn-dark">{ctaItem.cta || 'Learn More'}</Button></a>}
                              </div>
                                {ctaItem.image && ctaItem.image.sourceUrl &&
                                    <div className={`our-vision-platform-image-container`}>
                                      <img src={ctaItem.image.sourceUrl} alt={ctaItem.header || 'Default Image Description'} />
                                    </div>
                                }
                            </div>
                          ))
                        )}
                      </div>
                    </section>
                  </>
                );
                
            case "WordPress_HPage_Studiofields_OurVision_KeyCapabilitiesSection":
              return (
                <section style={{position: 'relative', backgroundColor: section?.wrapperBackgroundColor}}>
                  <div className="key-capabilities-container">
                    <div className="key-capabilities-header-image-desc-container">
                    <div className="key-capabilities-image-container">
                      <img src={section?.image.sourceUrl} alt='' />
                    </div>
                    <div className='key-capabilities-header-desc-container'>
                    <h3 className='key-capabilities-header'>{section?.keyCapabilitiesHeader}</h3>
                    {section?.keyCapabilitiesFields?.map((keyCapabilityField) => (
                      <div key={index}
                        className='key-capabilities-logo-header-desc-container '
                      >
                        <img src={keyCapabilityField.logo.sourceUrl} alt='' />
                        <div>
                          <h3>{keyCapabilityField.header}</h3>
                          <p>{keyCapabilityField.description}</p>
                        </div>
                      </div>
                      ))}
                      </div>
                  </div>
                    </div>
                </section>
              )
              
            case "WordPress_HPage_Studiofields_OurVision_HeaderImageSection":
              return (
                <section className='our-vision-coming-next-section'>
                  <div className="our-vision-coming-next-container">
                    <div className='our-vision-coming-next-header-btn-image-container'>
                      <div className='our-vision-coming-next-header-btn-container'>
                        <h2>{section?.mainHeader || "Wunder what's coming next at Wunderkind?"}</h2>
                        <Button href={section?.ctaHref} className="bg-3d55cc">{section?.cta || "Stay Tuned"}</Button>
                      </div>
                      <div className='our-vision-coming-next-image-container'>
                        <img src={section?.image.sourceUrl} alt='Artist With Innovator Characters' />
                      </div>
                    </div>
                  </div>
                </section>
              )
          default:
            return <></>;
        }
      })}
    </MainLayout>
  )
}

export const query = graphql`
query OurVisionPageQuery($title: String = "title") {
  wordPress {
    hPageTemplates(where: {slug: "our-vision"}) {
      nodes {
        hPages(where: {title: $title}) {
          nodes {
            title
            slug
            studioFields {
              ourVision {
                ... on WordPress_HPage_Studiofields_OurVision_ImageAndText {
                  cta
                  description
                  header
                  wrapperBackgroundColor
                  image {
                    sourceUrl
                  }
                  imageBackground {
                    sourceUrl
                  }
                }
                ... on WordPress_HPage_Studiofields_OurVision_LongDescriptionSection {
                  borderColor
                  description
                  wrapperBackgroundColor
                }
                ... on WordPress_HPage_Studiofields_OurVision_Divider {
                  backgroundColor
                  fieldGroupName
                  maskColor
                  orientation
                  position
                }
                ... on WordPress_HPage_Studiofields_OurVision_SkewedDivider {
                  backgroundColor
                }
                ... on WordPress_HPage_Studiofields_OurVision_FlipCardSection {
                  fieldGroupName
                  mainDescription
                  cardFields {
                    cardFrontBackgroundColor
                    cardFrontTextColor
                    cardBackBackgroundColor
                    cardBackTextColor
                    cardFrontDescription
                    cardBackDescription
                    cardImage {
                      sourceUrl
                    }
                  }
                  mainHeader
                  secondaryHeader
                  wrapperBackgroundColor
                  textColor
                }
                ... on WordPress_HPage_Studiofields_OurVision_VideoSection {
                  fieldGroupName
                  mainDescription
                  mainHeader
                  secondaryHeader
                  video
                  wrapperBackgroundColor
                }
                ... on WordPress_HPage_Studiofields_OurVision_PlatformApplicationSection {
                  fieldGroupName
                  mainHeader
                  subHeader
                  imageAndTextCta {
                    cta
                    ctaHref
                    description
                    header
                    image {
                      sourceUrl
                    }
                    imageBackground {
                      sourceUrl
                    }
                    leftOrRight
                  }
                  wrapperBackgroundColor
                }
                ... on WordPress_HPage_Studiofields_OurVision_KeyCapabilitiesSection {
                  wrapperBackgroundColor
                  image {
                    sourceUrl
                  }
                  keyCapabilitiesHeader
                  keyCapabilitiesFields {
                    logo {
                      sourceUrl
                    }
                    header
                    description
                  }
                  backgroundPattern {
                    sourceUrl
                  }
                }
                ... on WordPress_HPage_Studiofields_OurVision_HeaderImageSection {
                  cta
                  ctaHref
                  fieldGroupName
                  image {
                    sourceUrl
                  }
                  mainHeader
                  wrapperBackgroundColor
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export default OurVision